
    import { render as __gemini_ui_render } from "@gemini-ui/renderer";
    import MainLayout from "@gemini-ui/components/layouts/MainLayout";
import { SupportLink as _SupportLink } from "@gemini-ui/components/SupportLink";
import { Card, PageLayout, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const NotFound = _ref => {
  let {
    error,
    templateProps
  } = _ref;
  const {
    intl
  } = useIntl();
  return _jsx(MainLayout, {
    ...templateProps,
    error: error,
    children: _jsx(PageLayout, {
      children: _jsxs(PageLayout.Content, {
        children: [_jsx(Text.Heading, {
          size: "md",
          mt: 4,
          mb: 4,
          "data-testid": "page-not-found-404",
          children: intl.formatMessage({
            id: "Mz/gsa",
            defaultMessage: [{
              "type": 0,
              "value": "Page Not Found"
            }]
          })
        }), _jsxs(Card, {
          children: [_jsx(Text.Subhead, {
            "data-testid": "404-text",
            children: intl.formatMessage({
              id: "/Rkdyt",
              defaultMessage: [{
                "type": 0,
                "value": "That's a 404."
              }]
            })
          }), _jsx(Text.Body, {
            mt: 2,
            children: intl.formatMessage(defineMessage({
              id: "YEp55W",
              defaultMessage: [{
                "type": 0,
                "value": "Oops! The page you are looking for does not exist. If you feel you have reached this page in error, please "
              }, {
                "type": 8,
                "value": "SupportLink",
                "children": []
              }, {
                "type": 0,
                "value": "."
              }]
            }), {
              SupportLink: () => _jsx(_SupportLink, {})
            })
          })]
        })]
      })
    })
  });
};
NotFound.displayName = "NotFound";
export const RenderComponent = NotFound;
export default NotFound;
    __gemini_ui_render(RenderComponent);
  